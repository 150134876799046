<template>
  <div>

    <a-modal v-model:visible="modelShow" width="53%" :confirm-loading="loading" title="提现列表" @ok="onModalOk">
      <a-form :model="searchForm" layout="inline">
        <a-form-item label="">
          <a-input v-model:value="searchForm.orderNo" placeholder="订单号" />
        </a-form-item>
        <a-form-item label="">
          <a-select v-model:value="searchForm.status" style="width:140px" placeholder="交易状态">
            <a-select-option :value="1">未支付</a-select-option>
            <a-select-option :value="4">交易成功</a-select-option>
          </a-select>
        </a-form-item>
        <!-- <a-form-item label="">
          <a-select v-model:value="searchForm.orderStatus" style="width:140px" placeholder="到账状态">
            <a-select-option :value="1">未支付</a-select-option>
            <a-select-option :value="4">交易成功</a-select-option>
          </a-select>
        </a-form-item> -->
        <a-form-item label="">
          <a-range-picker :show-time="{ format: 'HH:mm' }" v-model:value="createTime" format="YYYY-MM-DD HH:mm" style="width:290px"
            :placeholder="['申请时间起', '申请时间末']" @change="createTimeChange" placeholder="申请时间段" />
        </a-form-item>
        <a-form-item label="">
          <a-range-picker :show-time="{ format: 'HH:mm' }" v-model:value="payDatetime" format="YYYY-MM-DD HH:mm" style="width:290px"
            :placeholder="['到账时间起', '到账时间末']" @change="payDatetimeChange" @ok="payDatetimeOk" placeholder="到账时间段" />
        </a-form-item>
        <a-form-item label="">
          <a-space>
            <a-button type="primary" @click="searchData" :loading="loading">搜索</a-button>
            <a-button @click="resetData" :loading="loading">重置</a-button>
          </a-space>
        </a-form-item>
      </a-form>
      <a-table size="small" :columns="columns" :row-key="record => record.bankCardNo" :data-source="listData" bordered
        :pagination="pagination" :loading="loading" @change="handleTableChange">
        <template #operation="{ record }">
          <span>
            <a @click="onCash(record)">提现</a>
          </span>
        </template>
        <template #download="{record}">
          <div>
            <a @click="download(record)">回单下载</a>
          </div>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import { withdrawApplyList,receiptDownload } from '@/api/carrier/cloudFlash'
export default {
  props: {
    carrierId: String,
    bizUserId: String
  },
  setup (props, context) {
    const state = reactive({
      carrierId: props.carrierId,
      bizUserId: props.bizUserId,
      loading: false,
      modelShow: false,
      listData: [],
      createTime: [],
      payDatetime: [],
      searchForm: {
        orderNo: null,
        status: null,
        orderStatus: null,
        createTimeStart: null,
        createTimeEnd: null,
        payDatetimeStart: null,
        payDatetimeEnd: null
      },
      mirrorSearchForm: {},
      columns: [
        // {
        //   title: '支付订单',
        //   dataIndex: 'bizOrderNo',
        //   align: 'center'
        // },
        {
          title: '订单号',
          dataIndex: 'orderNo',
          align: 'center'
        },
        {
          title: '金额: 元',
          dataIndex: 'amount',
          align: 'center'
        },
        {
          title: '银行卡号',
          dataIndex: 'bankCardNo',
          align: 'center'
        },
        {
          title: '会员类型',
          dataIndex: 'bankCardPro.label',
          align: 'center'
        },
        {
          title: '交易状态',
          dataIndex: 'status.label',
          align: 'center'
        },
        {
          title: '提现申请时间',
          dataIndex: 'createTime',
          align: 'center'
        },
        {
          title: '到账时间',
          dataIndex: 'payDatetime',
          align: 'center'
        },
        {
          title: '回单下载',
          dataIndex: 'download',
          slots: {
            customRender: 'download'
          }
        }
        // {
        //   title: '到账状态',
        //   dataIndex: 'orderStatus.label',
        //   align: 'center'
        // }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    onMounted(() => {
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
    })
    const onModalOk = () => {
      onWithdrawApplyList()
    }
    // 14.4 解绑银行卡
    const onWithdrawApplyList = () => {
      state.loading = true
      withdrawApplyList({
        ...state.searchForm,
        bizUserId: state.bizUserId,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        state.loading = false
      })
    }
    const handleTableChange = (page) => {
      state.pagination = page
      onWithdrawApplyList()
    }
    const createTimeChange = (e, v) => {
      state.searchForm.createTimeStart = v[0]
      state.searchForm.createTimeEnd = v[1]
    }
    const payDatetimeChange = (e, v) => {
      state.searchForm.payDatetimeStart = v[0]
      state.searchForm.payDatetimeEnd = v[1]
    }
    const searchData = () => {
      state.pagination.current = 1
      onWithdrawApplyList()
    }
    const resetData = () => {
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      state.createTime = []
      state.payDatetime = []
      searchData()
    }
    const download = (record) => {
      receiptDownload({
        bizOrderNo: record.bizOrderNo
      }).then(res=>{
        if(res.code === 10000){
          window.open(res.data.url, '_blank')
        }
      })
    }
    return {
      ...toRefs(state),
      onModalOk,
      searchData,
      resetData,
      download,
      handleTableChange,
      onWithdrawApplyList,
      createTimeChange,
      payDatetimeChange
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';

:deep(.ant-form-item) {
  margin-bottom: 10px;
}
</style>
