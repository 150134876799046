<template>
  <div>
    <a-modal v-model:visible="modelShow" width="600px" :confirm-loading="loading"
      :title="cashType === 0 ? '提现申请' : '确认支付'" @ok="onModalOk">
      <a-form ref="formRef" :model="addForm" layout="vertical" :rules="rules">
        <a-form-item label="金额" name="amount" v-if="cashType === 0">
          <a-input type="number" v-model:value="addForm.amount" />
        </a-form-item>
        <!-- <a-form-item label="验证码" v-if="cashType === 1" name="verificationCode">
          <a-input type="number" v-model:value="addForm.verificationCode" />
        </a-form-item> -->
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { reactive, ref, toRefs, createVNode } from 'vue'
import { message, Modal } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { withdrawApply, payByBackSMS } from '@/api/carrier/cloudFlash'

export default {
  components: {},
  props: {
    memberType: Number,
    bankCardNo: String,
    carrierId: String,
    bizUserId: String
  },
  setup (props, context) {
    const formRef = ref()
    const state = reactive({
      addForm: {
        amount: 0,
        bankCardNo: props.bankCardNo
      },
      carrierId: props.carrierId,
      bizUserId: props.bizUserId,
      bankCardPro: props.memberType === 2 ? 1 : 0,
      cashType: 0,
      loading: false,
      disabled: false,
      modelShow: false,
      rules: {
        amount: [{
          required: true,
          message: '请填写金额',
          trigger: 'blur'
        }],
        // verificationCode: [{
        //   required: true,
        //   message: '请填写验证码',
        //   trigger: 'blur'
        // }]
      }
    })

    const onModalOk = () => {
      formRef.value.validate().then(() => {
        // if (state.cashType === 0) {
        // onWithdrawApply()
        confirmModal()
        // } else {
        // onPayByBackSMS()
        // }
      })
    }
    const confirmModal = () => {
      if (state.addForm.amount * 1 > 0) {
        Modal.confirm({
          title: () => state.cashType === 0 ? '确认提现申请' : '确认支付金额',
          icon: () => createVNode(ExclamationCircleOutlined),
          content: () =>
            createVNode(
              'div',
              {
                style: 'color:red;',
              },
              `金额：${state.addForm.amount}`,
            ),
          onOk () {
            return new Promise((resolve, reject) => {
              onWithdrawApply(resolve)
            }).catch(() => console.log('Oops errors!'));
            // onWithdrawApply()
          },
          onCancel () {
            console.log('Cancel');
          },
          class: 'test',
        })
      }else message.error('金额数目不正确或不能为0')
    }
    const onWithdrawApply = (resolve) => {
      state.loading = true
      withdrawApply({
        bizUserId: state.bizUserId,
        amount: Number(state.addForm.amount),
        bankCardNo: state.addForm.bankCardNo,
        bankCardPro: state.bankCardPro,
        fee: 0
      }).then(res => {
        if (res.code === 10000) {
          // message.info(res.data.payFailMessage)
          message.success(res.msg)
          state.addForm.bizOrderNo = res.data.bizOrderNo
          state.addForm.tradeNo = res.data.orderNo
          state.modelShow = false
          context.emit('loadData')
          // state.cashType = 1
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        state.loading = false
        resolve()
      })
    }
    const onPayByBackSMS = () => {
      state.loading = true
      payByBackSMS({
        bizUserId: state.bizUserId,
        bizOrderNo: state.addForm.bizOrderNo,
        tradeNo: state.addForm.tradeNo,
        verificationCode: state.addForm.verificationCode
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.addForm = {}
          state.modelShow = false
          context.emit('loadData')
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => { state.loading = false })
    }

    return {
      ...toRefs(state),
      onModalOk,
      onWithdrawApply,
      onPayByBackSMS,
      formRef
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
@import '../common.less';
</style>
