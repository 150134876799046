<template>
  <div>
    <a-modal v-model:visible="modelShow" width="600px" :confirm-loading="loading" title="设置信息" @cancel="cancel" @ok="onModalOk">
      <a-form ref="_formRef" :model="addForm" layout="vertical" :rules="rules">
        <a-form-item label="企业对公账户" name="accountNo">
          <a-input v-model:value="addForm.accountNo" />
        </a-form-item>
        <a-form-item label="商户系统用户标识">
          <a-input v-model:value="addForm.bizUserId" :disabled="true" />
        </a-form-item>
        <a-form-item label="开户银行名称" name="parentBankName">
          <a-input v-model:value="addForm.parentBankName" placeholder="请输入银行简称（如：中国银行，去掉股份有限公司）"/>
        </a-form-item>
        <a-form-item label="开户行支行名称" name="bankName">
          <a-input v-model:value="addForm.bankName" :disabled="disabled" />
        </a-form-item>
        <a-form-item label="支付行号" name="unionBank">
          <a-input v-model:value="addForm.unionBank" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, ref } from 'vue'
import { bindCompanyAccount } from '@/api/carrier/cloudFlash'
import { message } from 'ant-design-vue'

export default {
  props: {
    carrierId: String,
    propsBizUserId: String,
    detail: Object
  },
  setup (props) {
    const _formRef = ref(null)
    const state = reactive({
      modelShow: false,
      loading: false,
      carrierId: props.carrierId,
      detailData: props.detail,
      mirrorForm: {},
      addForm: {
        accountNo: '',
        bankName: '',
        bizUserId: props.propsBizUserId,
        parentBankName: null,
        unionBank: ''
      },
      rules: {
        accountNo: [{
          required: true,
          message: '请填写企业对公账户',
          trigger: 'blur',
        }, {
          pattern: /^[0-9\-]+$/,
          message: '请填写正确的对公账户',
          trigger: 'blur'
        }],
        unionBank: [{
          required: true,
          message: '请填写支付行号',
          trigger: 'blur',
        }, {
          pattern: /^[0-9]{12}$/,
          message: '请填写正确的支付行号',
          trigger: 'blur',
        }],
        bankName: { required: true, message: '请填写开户行支行名称', trigger: 'blur' },
        parentBankName: { required: true, message: '请填写开户银行名称', trigger: 'blur' }
      }
    })
    onMounted(() => {
      state.mirrorForm = JSON.parse(JSON.stringify(state.addForm))
    })
    const onModalOk = () => {
      _formRef.value.validate().then(() => {
        state.addForm.
        bindCompanyAccount(state.addForm).then(res => {
          if (res.code === 10000) {
            message.success('添加成功')
            state.modelShow = false
            state.addForm = state.mirrorForm
          }
        })
      })
    }
    const cancel = () => {
      state.modelShow = false
      state.addForm = state.mirrorForm
    }

    return {
      ...toRefs(state),
      cancel,
      _formRef,
      onModalOk
    }
  }
}
</script>

<style lang="less" scoped>
</style>
