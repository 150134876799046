<template>
  <div>
    <a-modal v-model:visible="modelShow" width="53%" :confirm-loading="loading" title="退款列表" @ok="onModalOk">
      <a-form :model="searchForm" layout="inline">
        <a-form-item label="">
          <a-range-picker :show-time="{ format: 'HH:mm' }" v-model:value="payDatetime" format="YYYY-MM-DD HH:mm" :placeholder="['到账时间起', '到账时间末']"
            @change="payDatetimeChange" @ok="payDatetimeOk" placeholder="到账时间段" />
        </a-form-item>
        <a-form-item label="">
          <a-select v-model:value="searchForm.status" style="width:140px" placeholder="交易状态">
            <a-select-option :value="1">未支付</a-select-option>
            <a-select-option :value="4">交易成功</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-range-picker :show-time="{ format: 'HH:mm' }" v-model:value="createTime" format="YYYY-MM-DD HH:mm" :placeholder="['退款申请起', '退款申请末']"
            @change="createTimeChange" placeholder="退款申请时间段" />
        </a-form-item>
        <a-form-item label="">
          <a-space>
            <a-button type="primary" @click="searchData" :loading="loading">搜索</a-button>
            <a-button @click="resetData" :loading="loading">重置</a-button>
          </a-space>
        </a-form-item>
      </a-form>
      <a-table size="small" :columns="columns" :row-key="record => record.bizOrderNo" :data-source="listData" bordered
        :pagination="pagination" :loading="loading" @change="handleTableChange">
        <template #operation="{ record }">
          <span>
            <a @click="onRefund(record)">退款</a>
          </span>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import { refundList } from '@/api/carrier/cloudFlash'

export default {
  props: {
    carrierId: String,
    bizUserId: String
  },
  setup (props) {
    const state = reactive({
      modelShow: false,
      loading: false,
      listData: [],
      createTime: [],
      payDatetime: [],
      searchForm: {
        status: null,
        createTimeStart: null,
        createTimeEnd: null,
        payDatetimeStart: null,
        payDatetimeEnd: null
      },
      mirrorSearchForm: {},
      columns: [
        {
          title: '付款方',
          dataIndex: 'bizUserId',
          align: 'center'
        },
        {
          title: '退款金额/元',
          dataIndex: 'amount',
          align: 'center'
        },
        {
          title: '手续费',
          dataIndex: 'feeAmount',
          align: 'center'
        },
        {
          title: '到账时间',
          dataIndex: 'payDatetime',
          align: 'center'
        },
        {
          title: '交易状态',
          dataIndex: 'status.label',
          align: 'center'
        },
        {
          title: '退款申请时间',
          dataIndex: 'createTime',
          align: 'center'
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    onMounted(() => {
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
    })
    const onModalOk = () => {
      state.modelShow = false
    }
    const getRefundList = () => {
      state.loading = true
      refundList({
        ...state.searchForm,
        bizUserId: props.bizUserId,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
        }
      }).finally(() => { state.loading = false })
    }
    const createTimeChange = (e, v) => {
      state.searchForm.payDatetimeStart = v[0]
      state.searchForm.payDatetimeEnd = v[1]
    }
    const payDatetimeChange = (e, v) => {
      state.searchForm.createTimeStart = v[0]
      state.searchForm.createTimeEnd = v[1]
    }
    const searchData = () => {
      state.pagination.current = 1
      getRefundList()
    }
    const resetData = () => {
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      state.createTime = []
      state.payDatetime = []
      searchData()
    }
    return {
      ...toRefs(state),
      onModalOk,
      getRefundList,
      searchData,
      resetData,
      createTimeChange,
      payDatetimeChange
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.ant-form-item) {
  margin-bottom: 10px;
}
</style>
