<template>
  <div>
    <div>
      <div class="row-info">
        <div class="row-info-title" v-if="infoForm">{{ infoForm.memberType === 3 ? '账号信息' : '企业信息' }}</div>
        <div class="loading" v-if="loading">
          <a-spin />
        </div>
        <div>
          <div v-if="infoForm === null && loading === false">
            <!--            <div class="row-info-h">账号未创建需要先创建会员</div>-->
            <CreateMembers v-if="infoForm === null" ref="_createMembers" @loadData="loadData" @onUserId="onUserId"
              :carrierId="carrierId" />
          </div>
          <div v-if="infoForm != null && loading === false && infoForm.subCode != 30110">
            <a-row>
              <a-col :span="24" class="row-info-t"></a-col>
              <a-col :span="4" class="row-info-h">会员id:</a-col>
              <a-col :span="20" class="row-info-c">{{ infoForm.bizUserId }}</a-col>
              <!--              <a-col :span="4" class="row-info-c" style="display:flex;justify-content: space-between;align-items:center">-->
              <!--                <a-button v-if="infoForm.subCode === '30002'" class="searchLoading-button" @click="onUnlockMember()" :loading="refreshLoading" type="primary">-->
              <!--                  解锁会员-->
              <!--                </a-button>-->
              <!--                <a-popconfirm v-else title="是否确认锁定" ok-text="是" cancel-text="否" @confirm="onLockMember()">-->
              <!--                  <a-button  class="searchLoading-button-danger" danger type="primary"  :loading="refreshLoading">-->
              <!--                    锁定会员-->
              <!--                  </a-button>-->
              <!--                </a-popconfirm>-->

              <!--              </a-col>-->
              <a-col :span="4" class="row-info-h">会员类型:</a-col>
              <a-col :span="20" class="row-info-c"> {{ infoForm.memberType === 3 ? '个人' : '企业' }}</a-col>
              <a-col :span="4" class="row-info-h">会员别名:</a-col>
              <a-col :span="20" class="row-info-c"> {{ infoForm.memberInfo.memberAlias || '' }}</a-col>
              <a-col :span="4" class="row-info-h">手机号:</a-col>
              <a-col :span="14" class="row-info-c">{{ infoForm.memberInfo.phone }}</a-col>
              <a-col :span="6" class="row-info-c" style="display:flex;justify-content: space-between;align-items:center">
                <a-button v-if="infoForm.memberInfo.isPhoneChecked === false" class="searchLoading-button"
                  @click="onPhone(9)" :loading="refreshLoading" type="primary">
                  绑定手机
                </a-button>
                <a-button v-else class="searchLoading-button-danger" danger type="primary" @click="onPhone(6)"
                  :loading="refreshLoading">
                  取消绑定
                </a-button>
              </a-col>
              <a-col :span="4" class="row-info-h" v-if="infoForm.memberType === 2">企业信息编辑:</a-col>
              <a-col :span="14" class="row-info-h" v-if="infoForm.memberType === 2"></a-col>
              <a-col :span="6" class="row-info-c" v-if="infoForm.memberType === 2">
                <a-button v-if="infoForm.memberType === 2" class="searchLoading-button" @click="onEnterpriseConfig"
                  :loading="refreshLoading" type="primary">
                  设置/查看企业信息
                </a-button>
              </a-col>
              <a-col :span="4" class="row-info-h" v-if="infoForm.memberType === 2"> 对公账户:</a-col>
              <a-col :span="14" class="row-info-c" v-if="infoForm.memberType === 2">{{ infoForm.memberInfo.accountNoMask
              }}</a-col>
              <a-col :span="6" class="row-info-c" v-if="infoForm.memberType === 2">
                <a-button class="searchLoading-button" type="primary" @click="onRefresh(3)">银行卡操作</a-button>
              </a-col>
              <a-col :span="4" class="row-info-h"
                v-if="infoForm.memberType === 3 && infoForm.memberInfo.phone != null">个人认证:</a-col>
              <a-col :span="14" class="row-info-c"
                v-if="infoForm.memberType === 3 && infoForm.memberInfo.phone != null">{{ infoForm.memberInfo.name }}
                {{ infoForm.memberInfo.identityCard }}</a-col>
              <a-col :span="6" class="row-info-c" v-if="infoForm.memberType === 3 && infoForm.memberInfo.phone != null">
                <a-button v-if="infoForm.memberInfo.name === undefined || infoForm.memberInfo.name === ''"
                  class="searchLoading-button" @click="onRealName" :loading="refreshLoading" type="primary">
                  实名认证
                </a-button>
              </a-col>
              <a-col :span="4" class="row-info-h"
                v-if="infoForm.memberType === 3 && infoForm.memberInfo.name != null">银行卡:</a-col>
              <a-col :span="14" class="row-info-c" v-if="infoForm.memberType === 3 && infoForm.memberInfo.name != null">{{
                infoForm.memberInfo.bankCardNo }}</a-col>
              <a-col :span="3" class="row-info-c" v-if="infoForm.memberType === 3 && infoForm.memberInfo.name != null">
                <a-button class="searchLoading-button" @click="onRefresh(1)" :loading="refreshLoading" type="primary">
                  添加银行卡
                </a-button>
              </a-col>
              <a-col :span="3" class="row-info-c" v-if="infoForm.memberType === 3 && infoForm.memberInfo.name != null">
                <a-button class="" @click="onRefresh(3)" :loading="refreshLoading">
                  查询银行卡
                </a-button>
              </a-col>
              <a-col :span="4" class="row-info-h"
                v-if="infoForm.memberInfo.name != null || infoForm.memberInfo.accountNoMask != null">提现协议:</a-col>
              <a-col :span="14" class="row-info-c"
                v-if="infoForm.memberInfo.name != null || infoForm.memberInfo.accountNoMask != null">{{
                  infoForm.memberInfo.acctProtocolNo }}</a-col>
              <a-col :span="3" class="row-info-c"
                v-if="infoForm.memberInfo.name != null || infoForm.memberInfo.accountNoMask != null">
                <a-button v-if="infoForm.memberInfo.isSignContract === false && infoForm.memberType === 3"
                  class="searchLoading-button" @click="onAgreement(1)" :loading="refreshLoading" type="primary">
                  签约
                </a-button>
                <a-popconfirm v-if="infoForm.memberInfo.isSignContract === false && infoForm.memberType === 2"
                  title="选择提现签约类型" ok-text="企业法人提现" cancel-text="企业对公提现" @confirm="enterpriseProtocol(2)"
                  @cancel="enterpriseProtocol(3)">
                  <a-button v-if="infoForm.memberInfo.isSignContract === false" class="searchLoading-button"
                    type="primary">
                    签约
                  </a-button>
                </a-popconfirm>
              </a-col>
              <a-col :span="3" class="row-info-c"
                v-if="infoForm.memberInfo.name != null || infoForm.memberInfo.accountNoMask != null">
                <a-button v-if="infoForm.memberInfo.isSignContract === false && infoForm.memberType === 3"
                  @click="onAgreement(0)" :loading="refreshLoading">
                  查询
                </a-button>
                <a-popconfirm v-if="infoForm.memberInfo.isSignContract === false && infoForm.memberType === 2"
                  title="选择查询类型" ok-text="企业" cancel-text="法人" @confirm="onAgreement(3)" @cancel="enterpriseProtocol(2)">
                  <a-button v-if="infoForm.memberInfo.isSignContract === false">
                    查询
                  </a-button>
                </a-popconfirm>
              </a-col>
              <a-col :span="4" class="row-info-h"
                v-if="infoForm.memberInfo.name != null || infoForm.memberInfo.accountNoMask != null">余额:</a-col>
              <a-col :span="14" class="row-info-c"
                v-if="infoForm.memberInfo.name != null || infoForm.memberInfo.accountNoMask != null">
                <div v-if="balanceForm !== null">总额：{{ balanceForm.allAmount }}元 冻结额： {{ balanceForm.freezenAmount }}元
                  当日充值冻结额：
                  {{ balanceForm.depositFreezenAmount }}元 </div>
              </a-col>
              <a-col :span="3" class="row-info-c" style="display:flex;justify-content: space-between;align-items:center"
                v-if="infoForm.memberInfo.name != null || infoForm.memberInfo.accountNoMask != null">
                <a-button class="searchLoading-button" @click="onBalance()" :loading="refreshLoading" type="primary">
                  查询余额
                </a-button>
              </a-col>
              <a-col :span="3" class="row-info-c" style="display:flex;justify-content: space-between;align-items:center"
                v-if="infoForm.memberInfo.name != null || infoForm.memberInfo.accountNoMask != null">
                <a-button class="searchLoading-button" type="primary" @click="consumptionBtn">
                  消费列表
                </a-button>
              </a-col>
              <!-- <a-col :span="2" class="row-info-c">
                <a-button class="" @click="onCash()" v-if="infoForm.memberType===2" :loading="refreshLoading">
                  提现
                </a-button>
              </a-col> -->
              <a-col :span="18" class="row-info-c"
                v-if="infoForm.memberInfo.name != null || infoForm.memberInfo.accountNoMask != null">

              </a-col>
              <a-col :span="3" class="row-info-c"
                v-if="infoForm.memberInfo.name != null || infoForm.memberInfo.accountNoMask != null">
                <a-button class="searchLoading-button" @click="onCashTable(1)" :loading="refreshLoading" type="primary">
                  提现列表
                </a-button>
              </a-col>
              <a-col :span="3" class="row-info-c"
                v-if="infoForm.memberInfo.name != null || infoForm.memberInfo.accountNoMask != null">
                <a-button class="searchLoading-button" @click="onCashTable(2)" :loading="refreshLoading" type="primary">
                  退款列表
                </a-button>
              </a-col>
            </a-row>
            <!--            <div style="display:flex;justify-content: flex-end;padding:20px">-->
            <!--              <a-button class="searchLoading-button-danger" danger type="primary" @click="refundBtn">退款</a-button>-->
            <!--            </div>-->
            <PhoneBinding ref="_phoneBinding" @loadData="loadData" :carrierId="carrierId" :phoneType="phoneType"
              :bizUserId="bizUserId" />
            <Personal ref="_personal" @loadData="loadData" :carrierId="carrierId" :phoneType="phoneType"
              :bizUserId="bizUserId" />
            <EnterpriseMember v-if="infoForm.memberType === 2" ref="_enterpriseMember" :carrierId="carrierId"
              :propsBizUserId="bizUserId" :detail="infoForm.memberInfo" @loadData="loadData" />
            <AddAccounts v-if="infoForm.memberType === 2" ref="_addAccouns" :carrierId="carrierId" :detail="infoForm"
              :propsBizUserId="bizUserId" />
            <BankCard ref="_bankCard" @loadData="loadData" :iDCardNo="infoForm.memberInfo.iDCardNo"
              :carrierId="carrierId" :memberType="infoForm.memberType" :bizUserId="bizUserId"
              :name="infoForm.memberInfo.name" :phone="infoForm.memberInfo.phone"/>
            <Cash ref="_cash" @loadData="loadData" :carrierId="carrierId" :bizUserId="bizUserId" />
            <CashTable ref="_cashTable" @loadData="loadData" :carrierId="carrierId"
              :bankCardNo="infoForm.memberInfo.bankCardNo" :bizUserId="bizUserId" />
            <Refund ref="_refund" :carrierId="carrierId" :bizUserId="bizUserId" />
            <RefundList ref="_refundList" :carrierId="carrierId" :bizUserId="bizUserId" />
            <ConsumptionList ref="_consumptionList" :carrierId="carrierId" :bizUserId="bizUserId" />
          </div>
          <div v-if="infoForm !== null && loading === false && infoForm.subCode == 30110">
            <a-row>
              <a-col :span="4" class="row-info-h">企业信息:</a-col>
              <a-col :span="18" class="row-info-c"></a-col>
              <a-col :span="2" class="row-info-c">
                <a-button class="searchLoading-button" @click="onEnterpriseConfig" :loading="refreshLoading"
                  type="primary">
                  设置
                </a-button>
              </a-col>
            </a-row>
            <EnterpriseMember ref="_enterpriseMember" @loadData="loadData" :carrierId="carrierId" :detail="infoForm"
              :propsBizUserId="bizUserId" :phoneType="phoneType" />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { reactive, toRefs, ref } from 'vue'
import CreateMembers from './createMembers'
import PhoneBinding from './phoneBinding'
import EnterpriseMember from './enterpriseMember'
import Personal from './personal'
import BankCard from './bankCard'
import Cash from './cash'
import CashTable from './cashTable'
import AddAccounts from './addAccounts.vue'
import RefundList from './refundList.vue'
import Refund from './refund.vue'
import ConsumptionList from './consumptionList.vue'
import { signAcctProtocol, getMemberInfo, signContractQuery, queryBalance, lockMember, unlockMember } from '@/api/carrier/cloudFlash'
import { message } from 'ant-design-vue'
export default {
  components: {
    CreateMembers,
    Personal,
    EnterpriseMember,
    BankCard,
    Cash,
    CashTable,
    AddAccounts,
    PhoneBinding,
    Refund,
    RefundList,
    ConsumptionList
  },
  props: { propsBizUserId: String },
  setup (props,context) {
    const state = reactive({
      infoForm: null,
      refreshLoading: false,
      balanceForm: null,
      phoneType: 9,
      bizUserId: props.propsBizUserId,
      loading: false
    })
    const _createMembers = ref()
    const _enterpriseMember = ref()
    const _phoneBinding = ref()
    const _personal = ref()
    const _bankCard = ref()
    const _cash = ref()
    const _cashTable = ref()
    const _addAccouns = ref(null)
    const _refund = ref(null)
    const _refundList = ref(null)
    const _consumptionList = ref(null)
    const loadData = () => {
      if (state.bizUserId == null) {
        return
      }
      state.loading = true
      getMemberInfo({
        bizUserId: state.bizUserId
      }).then(res => {
        if (res.code === 10000) {
          console.log('res', res)
          state.infoForm = res.data
          setTimeout(() => {
            _bankCard.value.closeWindow()
          }, 10)
          if (state.infoForm.subCode === '30002') {
            res.data.memberInfo = {}
          }
          if (state.infoForm.bizUserId != null) {
            state.bizUserId = state.infoForm.bizUserId
          }
        }
      }).catch(err => { console.log(err) })
        .finally(() => { state.loading = false })
    }
    // 绑定 取消手机号
    const onPhone = (type) => {
      state.phoneType = type
      _phoneBinding.value.modelShow = true
    }
    // 设置企业信息
    const onEnterpriseConfig = () => {
      _enterpriseMember.value.modelShow = true
    }
    // 添加企业对公账户
    const addAccountsData = () => {
      _addAccouns.value.modelShow = true
    }
    // 实名认证
    const onRealName = () => {
      _personal.value.modelShow = true
    }
    // 绑定银行卡
    const onRefresh = (type) => {
      _bankCard.value.modelShow = true
      _bankCard.value.bankType = type
      if (type === 3) {
        _bankCard.value.onQueryBankCard()
      }
    }
    // 协议
    const onAgreement = (type) => {
      if (type === 0) {
        onQuerySigning(1)
      } else if (type === 1) {
        onSigning()
      } else if (type === 2) {
        onQuerySigning(2)
      } else if (type === 3) {
        onQuerySigning(3)
      }
    }
    // 企业提现协议
    const enterpriseProtocol = type => {
      signAcctProtocol({ bizUserId: state.bizUserId, signAcctType: type }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          window.open(res.data)
          loadData()
        }
      })
    }
    // 签约
    const onSigning = () => {
      // * 个人会员：1
      //   * 企业会员法人提现，2
      // * 企业会员对公户提现，3
      signAcctProtocol({ bizUserId: state.bizUserId, signAcctType: 1 }).then(res => {
        if (res.code === 10000) {
          // message.success(res.msg)
          window.open(res.data)
          loadData()
        }
      })
    }
    // 查询签约
    const onQuerySigning = (e) => {
      signContractQuery({
        bizUserId: state.bizUserId,
        signAcctType: e
      }).then(res => {
        if (res.code === 10000) {
          window.open(res.data)
          loadData()
        }
      })
    }
    // 余额
    const onBalance = () => {
      queryBalance({
        bizUserId: state.bizUserId
      }).then(res => {
        if (res.code === 10000) {
          state.balanceForm = res.data
        }
      })
    }
    // 提现
    const onCash = () => {
      _cash.value.modelShow = true
      _cash.value.bankCardPro = 1
      _cash.value.cashType = 0
      _cash.value.addForm.bankCardNo = state.infoForm.memberInfo.accountNo
    }
    // 提现列表+退款列表
    const onCashTable = numType => {
      switch (numType) {
        case 1:
          _cashTable.value.modelShow = true
          _cashTable.value.onWithdrawApplyList()
          break
        case 2:
          _refundList.value.modelShow = true
          _refundList.value.getRefundList()
          break
      }
    }
    // 退款
    const refundBtn = () => {
      _refund.value.modelShow = true
    }
    // 消费申请列表
    const consumptionBtn = () => {
      _consumptionList.value.modelShow = true
      _consumptionList.value.getConsumeApplyList()
    }
    // 锁定会员
    const onLockMember = () => {
      lockMember({
        bizUserId: state.infoForm.bizUserId
      }).then(res => {
        if (res.code === 10000) {
          loadData()
        }
      })
    }
    // 解锁会员
    const onUnlockMember = () => {
      unlockMember({
        bizUserId: state.bizUserId
      }).then(res => {
        if (res.code === 10000) {
          loadData()
        }
      })
    }
    // 更换userId
    const onUserId = (e) => {
      console.log('更换userId', e)
      state.bizUserId = e
      context.emit('addSuccess', true)
    }
    return {
      ...toRefs(state),
      _createMembers,
      _phoneBinding,
      _personal,
      _enterpriseMember,
      _bankCard,
      _cash,
      _cashTable,
      _addAccouns,
      _refund,
      _refundList,
      _consumptionList,
      onPhone,
      addAccountsData,
      onEnterpriseConfig,
      onRealName,
      onRefresh,
      enterpriseProtocol,
      onAgreement,
      onCash,
      onCashTable,
      onSigning,
      onQuerySigning,
      onBalance,
      loadData,
      refundBtn,
      onUnlockMember,
      onUserId,
      onLockMember,
      consumptionBtn
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
@import '../common.less';</style>
