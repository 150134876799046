<template>
  <div >
    <a-modal v-model:visible="modelShow"  width="600px" :confirm-loading="loading" :title="phoneType===9?'实名认证':'取消绑定'" @ok="onModalOk">
      <a-form  ref="formRef"  :model="addForm" layout="vertical" :rules="rules">
        <a-form-item label="姓名" name="name">
          <a-input v-model:value="addForm.name" />
        </a-form-item>
        <a-form-item label="身份证号码"  name="identityNo" >
          <a-input v-model:value="addForm.identityNo"/>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { reactive, ref, toRefs } from 'vue'
import { message } from 'ant-design-vue'
import { setRealName } from '@/api/carrier/cloudFlash'
import { idNumberValidate } from '@/utils/validate'

export default {
  components: {},
  props: {
    carrierId: String,
    bizUserId: String,
    phoneType: Number
  },
  setup (props, context) {
    const formRef = ref()
    const state = reactive({
      addForm: {},
      carrierId: props.carrierId,
      phoneType: props.phoneType,
      bizUserId: props.bizUserId,
      loading: false,
      disabled: false,
      modelShow: false,
      rules: {
        name: [{
          required: true,
          message: '请填写姓名',
          trigger: 'blur'
        }],
        identityNo: [{
          required: true,
          message: '请填写身份证',
          trigger: 'blur'
        }, {
          pattern: idNumberValidate,
          message: '请填写正确的身份证',
          trigger: 'blur'
        }]
      }
    })

    const onModalOk = () => {
      formRef.value.validate().then(() => {
        state.loading = true
        state.addForm.carrierId = state.carrierId
        onAuthentication()
      })
    }
    const onAuthentication = () => {
      state.loading = true
      state.addForm.carrierId = state.carrierId
      setRealName({
        carrierId: state.carrierId,
        bizUserId: state.bizUserId,
        name: state.addForm.name,
        identityNo: state.addForm.identityNo
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.addForm = {}
          state.modelShow = false
          context.emit('loadData')
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => { state.loading = false })
    }

    return {
      ...toRefs(state),
      onModalOk,
      onAuthentication,
      formRef
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
@import '../common.less';
</style>
