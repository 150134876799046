<template>
  <div style="width: 600px;margin: 50px auto">
    <a-form :model="addForm" layout="vertical">
      <!--        <a-form-item label="会员id">-->
      <!--          <a-input v-model:value="addForm.bizUserId"/>-->
      <!--        </a-form-item>-->
      <a-form-item label="会员类型">
        <a-select allowClear v-model:value="addForm.memberType" :options="$store.state.enumAll.membersTypeEnum"
          placeholder="请选择会员类型" style="width:600px" />
      </a-form-item>
      <a-form-item label="会员别名">
        <a-input v-model:value="addForm.memberAlias" placeholder="输入会员别名" />
      </a-form-item>

    </a-form>
    <div style="display:flex;justify-content:flex-end;margin-top: 40px">
      <a-button @click="onAdd" :loading="loading" :disabled="!addForm.memberType||addForm.memberAlias==''">创建会员</a-button>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { message } from 'ant-design-vue'
import { createMember } from '@/api/carrier/cloudFlash'

export default {
  components: {
  },
  props: { carrierId: String },
  setup (props, context) {
    const state = reactive({
      addForm: {memberAlias:''},
      carrierId: props.carrierId,
      loading: false
    })
    const onAdd = () => {
      state.loading = true
      state.addForm.carrierId = state.carrierId
      createMember(state.addForm).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.addForm = {}
          context.emit('onUserId', res.data)
          context.emit('loadData')
        }
      }).catch(err => { console.log(err) })
        .finally(() => { state.loading = false })
    }
    return {
      ...toRefs(state),
      onAdd
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
@import '../common.less';
</style>
