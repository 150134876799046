<template>
  <div>
    <a-modal v-model:visible="modelShow" width="600px" :confirm-loading="loading" :title="phoneType === 9 ? '绑定手机' : '取消绑定'"
      @ok="onModalOk">
      <a-form ref="formRef" :model="addForm" layout="vertical" :rules="rules">
        <a-form-item label="手机号" name="phone">
          <a-input v-model:value="addForm.phone" />
        </a-form-item>
        <a-form-item label="验证码">
          <a-input v-model:value="addForm.code" />
        </a-form-item>
        <a-form-item>
          <a-button class="searchLoading-button" v-show="!disabled" @click="onCode" :loading="loading" type="primary">
            {{ codeTest }}
          </a-button>
          <a-button class="disabled-btn" v-show="disabled" :disabled="true">
            {{ countdown }}
          </a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { reactive, ref, toRefs } from 'vue'
import { message } from 'ant-design-vue'
import { bindPhone, sendVerificationCode, unbindPhone } from '@/api/carrier/cloudFlash'
import { mobileValidate } from '@/utils/validate'

export default {
  components: {},
  props: {
    carrierId: String,
    bizUserId: String,
    phoneType: Number
  },
  setup (props, context) {
    const formRef = ref()
    const state = reactive({
      codeTest: '获取验证码',
      addForm: {},
      carrierId: props.carrierId,
      phoneType: props.phoneType,
      bizUserId: props.bizUserId,
      countdown: '60秒后可重新获取',
      loading: false,
      disabled: false,
      modelShow: false,
      rules: {
        phone: [{
          required: true,
          message: '请填写电话号码',
          trigger: 'blur'
        }, {
          pattern: mobileValidate,
          message: '请填写正确的电话号码',
          trigger: 'blur'
        }]
      }
    })
    const onCode = () => {
      formRef.value.validate().then(() => {
        state.loading = true
        state.addForm.carrierId = state.carrierId
        sendVerificationCode({
          bizUserId: state.bizUserId,
          carrierId: state.carrierId,
          codeType: props.phoneType,
          phone: state.addForm.phone
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            let time = 60
            state.disabled = true
            setInterval(() => {
              if (time === 0) {
                state.disabled = false
                state.codeTest = '重新获取'
                clearInterval()
              } else {
                state.countdown = time + '秒后重新获取'
                time = Number(time) - 1
              }
            }, 1000)
          }
        }).catch(err => {
          console.log(err)
        }).finally(() => { state.loading = false })
      })
    }
    const onModalOk = () => {
      console.log(props.phoneType)
      formRef.value.validate().then(() => {
        if (state.addForm.code.length < 1) {
          message.error('请填写验证码')
        }
        state.loading = true
        state.addForm.carrierId = state.carrierId
        if (props.phoneType === 9) {
          onBindPhone()
          console.log('onBindPhone')
        } else {
          onUnbindPhone()
          console.log('onUnbindPhone')
        }
      })
    }
    const onBindPhone = () => {
      state.loading = true
      state.addForm.carrierId = state.carrierId
      bindPhone({
        bizUserId: state.bizUserId,
        code: state.addForm.code,
        phone: state.addForm.phone
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.addForm = {}
          state.modelShow = false
          context.emit('loadData')
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => { state.loading = false })
    }
    const onUnbindPhone = () => {
      state.loading = true
      state.addForm.carrierId = state.carrierId
      unbindPhone({
        bizUserId: state.bizUserId,
        code: state.addForm.code,
        phone: state.addForm.phone
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.addForm = {}
          state.modelShow = false
          context.emit('loadData')
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => { state.loading = false })
    }
    return {
      ...toRefs(state),
      onCode,
      onModalOk,
      onUnbindPhone,
      onBindPhone,
      formRef
    }
  }
}
</script>

<style lang="less" scoped>
@import '../common.less';
@import './index.less';
</style>
