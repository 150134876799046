<template>
  <div>

    <a-modal v-model:visible="modelShow" width="600px" :confirm-loading="loading"
      :title="[bankType === 1 ? '请求绑定银行卡' : '', bankType === 3 ? '查询银行卡' : '', bankType === 4 ? '解绑银行卡' : '']" @ok="onModalOk">
      <a-tabs type="bankType" v-model:activeKey="bankType">
        <a-tab-pane :key="1" tab="添加"></a-tab-pane>
        <a-tab-pane :key="3" tab="查询"></a-tab-pane>
        <a-tab-pane :key="4" tab="解绑"></a-tab-pane>
      </a-tabs>
      <a-form ref="formRef" :model="addForm" name="name" layout="vertical" :rules="rules">
        <a-form-item label="姓名" v-if="bankType === 1">
          <a-input v-model:value="addForm.name" placeholder="如果是企业会员，请填写法人姓名"/>
        </a-form-item>
        <a-form-item label="银行预留手机" name="phone" v-if="bankType === 1 || bankType === 2">
          <a-input v-model:value="addForm.phone" placeholder="银行预留手机"/>
        </a-form-item>
        <a-form-item label="证件号码" name="identityNo" v-if="bankType === 1">
          <a-input v-model:value="addForm.identityNo" placeholder="请输入身份证号"/>
        </a-form-item>
        <a-form-item label="银行卡号" v-if="bankType === 3">
          <a-input v-model:value="addForm.cardNo" placeholder="银行卡号" />
        </a-form-item>
        <a-form-item label="银行卡号" name="cardNo" v-if="bankType !== 2 && bankType !== 3">
          <a-input v-model:value="addForm.cardNo" type="number" placeholder="银行卡号" />
        </a-form-item>
        <!-- <a-form-item label="验证码" name="verificationCode" v-if="bankType===2">
          <a-input v-model:value="addForm.verificationCode" placeholder="短信验证码" />
        </a-form-item> -->
        <!-- <a-form-item label="" name="verificationCode" v-if="bankType === 3">

        </a-form-item> -->
      </a-form>
      <a-table v-if="bankType === 3" size="small" :columns="columns" :row-key="record => record.bankCardNo"
        :data-source="listData" bordered :pagination="pagination" :loading="loading" @change="handleTableChange">
        <template #operation="{ record }">
          <span>
            <a @click="onCash(record)">提现</a>
          </span>
        </template>
      </a-table>
      <Cash ref="_cash" @loadData="onQueryBankCard" :carrierId="carrierId" :memberType="memberType"
        :bankCardNo="bankCardNo" :bizUserId="bizUserId" />
    </a-modal>
  </div>
</template>

<script>
import { reactive, ref, toRefs } from 'vue'
import { message } from 'ant-design-vue'
import { applyBindBankCard, bindBankCard, queryBankCard, unbindBankCard } from '@/api/carrier/cloudFlash'
import { idNumberValidate, mobileValidate } from '@/utils/validate'
import Cash from './cash'
export default {
  components: {
    Cash
  },
  props: {
    memberType: Number,
    bankCardNo: String,
    name: String,
    phone: String,
    iDCardNo: String,
    carrierId: String,
    bizUserId: String
  },
  setup (props, context) {
    const formRef = ref()
    const _cash = ref()
    console.log(props);
    const state = reactive({
      addForm: {
        name: props.name || '',
        phone: props.phone || '',
        identityNo: props.iDCardNo || '',
        cardNo: ''
      },
      bankCardNo: '',
      memberType: props.memberType,
      carrierId: props.carrierId,
      bankType: 1,
      bizUserId: props.bizUserId,
      loading: false,
      disabled: false,
      modelShow: false,
      listData: [],
      columns: [
        {
          title: '银行',
          dataIndex: 'bankName',
          align: 'center'
        },
        {
          title: '银行卡号',
          dataIndex: 'bankCard',
          align: 'center'
        },
        {
          title: '手机号',
          dataIndex: 'phone',
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'bindTime',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      rules: {
        name: [{
          required: true,
          message: '请填写姓名',
          trigger: 'blur'
        }],
        verificationCode: [{
          required: true,
          message: '请填写验证码',
          trigger: 'blur'
        }],
        identityNo: [{
          required: true,
          message: '请填写身份证',
          trigger: 'blur'
        }, {
          pattern: idNumberValidate,
          message: '请填写正确的身份证',
          trigger: 'blur'
        }],
        phone: [{
          required: true,
          message: '请填写业务对接人电话',
          trigger: 'blur'
        }, {
          pattern: mobileValidate,
          message: '请填写正确的电话号码',
          trigger: 'blur'
        }],
        cardNo: [{
          required: true,
          message: '请填银行卡号',
          trigger: 'blur'
        }]
      },
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    const onModalOk = () => {
      if (state.bankType === 1) {
        onRequest()
      } else if (state.bankType === 2) {
        // onBindBankCard()
      } else if (state.bankType === 3) {
        onQueryBankCard()
      } else if (state.bankType === 4) {
        onUnbindBankCard()
      }
    }
    // 请求绑定银行卡
    const onRequest = () => {
      formRef.value.validate().then(() => {
        state.loading = true
        applyBindBankCard({
          bizUserId: state.bizUserId,
          carrierId: state.carrierId,
          cardNo: state.addForm.cardNo,
          identityNo: state.addForm.identityNo,
          name: state.addForm.name,
          phone: state.addForm.phone
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.addForm.tranceNum = res.data.tranceNum
            state.bankType = 2
            context.emit('loadData')
          }
        }).catch(err => {
          console.log(err)
        }).finally(() => { state.loading = false })
      })
    }
    // 14.2 确认绑定银行卡
    const onBindBankCard = () => {
      formRef.value.validate().then(() => {
        state.loading = true
        bindBankCard({
          bizUserId: state.bizUserId,
          carrierId: state.carrierId,
          tranceNum: state.addForm.tranceNum,
          verificationCode: state.addForm.verificationCode,
          phone: state.addForm.phone
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.addForm = {}
            state.modelShow = false
            context.emit('loadData')
          }
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          state.loading = false
        })
      })
    }
    // 14.3 查询绑定银行卡
    const onQueryBankCard = () => {
      state.loading = true
      queryBankCard({
        bizUserId: state.bizUserId,
        carrierId: state.carrierId
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        state.loading = false
      })
    }
    // 14.4 解绑银行卡
    const onUnbindBankCard = () => {
      formRef.value.validate().then(() => {
        unbindBankCard({
          bizUserId: state.bizUserId,
          carrierId: state.carrierId,
          cardNo: state.addForm.cardNo
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.addForm = {}
            state.modelShow = false
            context.emit('loadData')
          }
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          state.loading = false
        })
      })
    }
    // 提现
    const onCash = (e) => {
      console.log('e.bankCardNo', e.bankCardNo)
      state.bankCardNo = e.bankCardNo
      _cash.value.modelShow = true
      _cash.value.cashType = 0
      _cash.value.addForm.bankCardNo = e.bankCardNo
    }
    const closeWindow = () =>{
      state.modelShow = false
    }
    return {
      ...toRefs(state),
      onModalOk,
      onBindBankCard,
      onQueryBankCard,
      onUnbindBankCard,
      closeWindow,
      onRequest,
      onCash,
      _cash,
      formRef
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
@import '../common.less';</style>
