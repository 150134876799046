<template>
  <div>
    <a-modal v-model:visible="modelShow" width="53%" :confirm-loading="loading" title="消费列表" footer="" @ok="onModalOk">
      <a-form :model="searchForm" layout="inline">
        <a-form-item label="">
          <a-input v-model:value="searchForm.bizOrderNo" placeholder="订单号" />
        </a-form-item>
        <a-form-item label="">
          <a-select v-model:value="searchForm.orderPayType" style="width:140px" placeholder="订单状态">
            <a-select-option :value="2">托管代收申请</a-select-option>
            <a-select-option :value="3">单笔托管代付</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-select v-model:value="searchForm.orderStatus" style="width:140px" placeholder="订单支付状态">
            <a-select-option :value="1">未支付</a-select-option>
            <a-select-option :value="4">交易成功</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-select v-model:value="searchForm.payMethod" :options="$store.state.enumAll.payTypeEnum" style="width:140px" placeholder="支付方式">
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-space>
            <a-button type="primary" @click="searchData" :loading="loading">搜索</a-button>
            <a-button @click="resetData" :loading="loading">重置</a-button>
          </a-space>
        </a-form-item>
      </a-form>
      <div>
        <a-table size="small" :columns="columns" :row-key="record => record.bizOrderNo" :data-source="listData" bordered
          :pagination="pagination" :loading="loading" @change="handleTableChange">
          <template #operation="{ record }">
            <span>
              <a @click="onRefund(record)">退款</a>
            </span>
          </template>
        </a-table>
      </div>
      <Refund ref="_refund" :carrierId="carrierId" />
    </a-modal>
  </div>
</template>

<script>
import { reactive, ref, toRefs, onMounted } from 'vue'
import { consumeApplyList } from '@/api/carrier/cloudFlash'
import Refund from './refund'
export default {
  components: {
    Refund
  },
  props: {
    carrierId: String,
    bizUserId: String
  },
  setup (props) {
    const _refund = ref(null)
    const state = reactive({
      carrierId: props.carrierId,
      bizUserId: props.bizUserId,
      loading: false,
      modelShow: false,
      searchForm: {
        bizOrderNo: null,
        orderStatus: null,
        payMethod: null
      },
      mirrorSearchForm: {},
      listData: [],
      columns: [
        // {
        //   title: '支付订单',
        //   dataIndex: 'bizOrderNo',
        //   align: 'center'
        // },
        {
          title: '消费订单号',
          dataIndex: 'bizOrderNo',
          align: 'center'
        },
        {
          title: '订单支付状态',
          dataIndex: 'orderStatus.label',
          align: 'center'
        },
        {
          title: '订单状态',
          dataIndex: 'orderPayType.label',
          align: 'center'
        },
        {
          title: '支付方式',
          dataIndex: 'payMethod.label',
          align: 'center'
        },
        {
          title: '金额',
          dataIndex: 'amount',
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'subject',
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '80px',
          align: 'center',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    const onModalOk = () => {
      state.modelShow = false
    }
    onMounted(() => {
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
    })
    const getConsumeApplyList = () => {
      state.loading = true
      consumeApplyList({
        ...state.searchForm,
        recieverId: state.bizUserId,
        // recieverId: 'pdd',
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
          state.loading = false
        }
      })
    }
    const handleTableChange = (page, size) => {
      state.pagination = page
      getConsumeApplyList()
    }
    const onRefund = (e) => {
      _refund.value.modelShow = true
      _refund.value.form.amount = e.amount
      _refund.value.form.oriBizOrderNo = e.bizOrderNo
      _refund.value.form.bizUserId = e.payerId
    }
    const searchData = () => {
      state.pagination.current = 1
      getConsumeApplyList()
    }
    const resetData = () =>{
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      searchData()
    }
    return {
      ...toRefs(state),
      onModalOk,
      searchData,
      resetData,
      getConsumeApplyList,
      onRefund,
      _refund,
      handleTableChange
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.ant-form-item) {
  margin-bottom: 10px;
}
</style>
