<template>
  <div>
    <a-modal v-model:visible="modelShow" width="600px" :okText="okText" :confirm-loading="loading" title="设置企业信息"
      @ok="editData">
      <template #footer>
        <a-button key="back" @click="modelShow = false">取消</a-button>
        <a-button v-if="footer" key="submit" type="primary" :loading="loading" @click="editData">{{ okText }}</a-button>
      </template>
      <div class="form-set">
        <div
          v-show="setForm.accountNoMask !== '' && setForm.accountNoMask !== null && setForm.accountNoMask !== undefined && !editShow">
          <a-form-item label="企业对公账户">
            <div>{{ setForm.accountNoMask }}</div>
          </a-form-item>
          <!--          <a-form-item label="商户系统用户标识">-->
          <!--            <span>{{ setForm.bizUserId }}</span>-->
          <!--          </a-form-item>-->
          <a-form-item label="开户行地区代码">
            <span>{{ setForm.bankCityNo }}</span>
          </a-form-item>
          <a-form-item label="开户行支行名称">
            <span>{{ setForm.bankName }}</span>
          </a-form-item>
          <a-form-item label="开户银行名称">
            <span>{{ setForm.parentBankName }}</span>
          </a-form-item>
          <a-form-item label="开户行所在省">
            <span>{{ setForm.province }}</span>
          </a-form-item>
          <a-form-item label="开户行所在市">
            <span>{{ setForm.city }}</span>
          </a-form-item>
          <a-form-item label="企业地址">
            <span>{{ setForm.companyAddress }}</span>
          </a-form-item>
          <a-form-item label="企业名称，如有括号，用中文格式（）">
            <span>{{ setForm.companyName }}</span>
          </a-form-item>
          <a-form-item label="法人姓名">
            <span>{{ setForm.legalName }}</span>
          </a-form-item>
          <a-form-item label="法人手机号码">
            <span>{{ setForm.legalPhone }}</span>
          </a-form-item>
          <a-form-item label="法人证件类型">
            <span>{{ setForm.identityTypeName }}</span>
          </a-form-item>
          <a-form-item label="法人证件号码">
            <span>{{ setForm.legalIdsMask }}</span>
          </a-form-item>
          <a-form-item label="联系电话">
            <span>{{ setForm.telephone }}</span>
          </a-form-item>
          <a-form-item label="统一社会信用（一证）">
            <span>{{ setForm.uniCredit }}</span>
          </a-form-item>
          <!--          <a-form-item label="统一社会信用到期时间">-->
          <!--            <span>{{ setForm.expLicense }}</span>-->
          <!--          </a-form-item>-->
          <a-form-item label="支付行号">
            <span>{{ setForm.unionBank }}</span>
          </a-form-item>
        </div>
        <a-form ref="_formRef" :model="setForm" layout="vertical" :rules="rules"
          v-show="!setForm.accountNoMask || editShow">
          <a-form-item label="企业对公账户" name="accountNo">
            <a-input v-model:value="setForm.accountNo" />
          </a-form-item>
          <!--          <a-form-item label="商户系统用户标识">-->
          <!--            <a-input v-model:value="setForm.bizUserId" :disabled="disabled&&setForm.accountNoMask" />-->
          <!--          </a-form-item>-->
          <a-form-item label="开户行地区代码">
            <a-input v-model:value="setForm.bankCityNo" :disabled="disabled && setForm.accountNoMask" />
          </a-form-item>
          <a-form-item label="开户行支行名称" name="bankName">
            <a-input v-model:value="setForm.bankName" :disabled="disabled && setForm.accountNoMask" />
          </a-form-item>
          <a-form-item label="开户银行名称" name="parentBankName">
            <a-input v-model:value="setForm.parentBankName" :disabled="disabled && setForm.accountNoMask"
              placeholder="请输入银行简称（如：中国银行，去掉股份有限公司）" />
          </a-form-item>
          <a-form-item label="开户行所在省">
            <a-input v-model:value="setForm.province" :disabled="disabled && setForm.accountNoMask" />
          </a-form-item>
          <a-form-item label="开户行所在市">
            <a-input v-model:value="setForm.city" :disabled="disabled && setForm.accountNoMask" />
          </a-form-item>
          <a-form-item label="企业地址">
            <a-input v-model:value="setForm.companyAddress" :disabled="disabled && setForm.accountNoMask" />
          </a-form-item>
          <a-form-item label="企业名称，如有括号，用中文格式（）" name="companyName">
            <a-input v-model:value="setForm.companyName" :disabled="disabled && setForm.accountNoMask" />
          </a-form-item>
          <a-form-item label="法人姓名" name="legalName">
            <a-input v-model:value="setForm.legalName" :disabled="disabled && setForm.accountNoMask" />
          </a-form-item>
          <a-form-item label="法人手机号码" name="legalPhone">
            <a-input v-model:value="setForm.legalPhone" :disabled="disabled && setForm.accountNoMask" />
          </a-form-item>
          <a-form-item label="法人证件类型" name="identityType">
            <!-- <a-select v-model:value="setForm.identityType" @change="accountNoChange">
              <a-select-option v-for="item in documentType" :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
            </a-select> -->
            <a-select v-model:value="setForm.identityType" @change="accountNoChange"
              :disabled="disabled && setForm.accountNoMask">
              <a-select-option v-for="item in documentType" :key="item.value" :value="item.value">{{ item.label
              }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="法人证件号码" name="legalIds" v-if="!setForm.accountNoMask">
            <a-input v-model:value="setForm.legalIds" :disabled="disabled && setForm.accountNoMask" />
          </a-form-item>
          <a-form-item label="联系电话">
            <a-input v-model:value="setForm.telephone" :disabled="disabled && setForm.accountNoMask" />
          </a-form-item>
          <a-form-item label="统一社会信用（一证）" name="uniCredit">
            <a-input v-model:value="setForm.uniCredit" :disabled="disabled && setForm.accountNoMask" />
          </a-form-item>
          <!--          <a-form-item label="统一社会信用到期时间">-->
          <!--            <a-date-picker v-model:value="setForm.expLicense" @change="dateChange" :disabled="disabled&&setForm.accountNoMask" />-->
          <!--          </a-form-item>-->
          <a-form-item label="支付行号" name="unionBank">
            <a-input v-model:value="setForm.unionBank" :disabled="disabled && setForm.accountNoMask" />
          </a-form-item>
        </a-form>
      </div>
      <div style="display:flex;justify-content: space-around;text-align:center"
        v-show="setForm.accountNoMask !== '' && setForm.accountNoMask !== null && setForm.accountNoMask !== undefined && !editShow">
        <div @click="flag = 1" style="display:flex;flex-direction: column;">
          <div>营业执照</div>
          <a-image v-if="fileImg.license" style="width:150px;height:150px" :src="fileImg.license" />
          <a-upload v-model:file-list="fileList" list-type="picture" :show-upload-list="false"
            :before-upload="beforeUpload" @change="imgUpload">
            <div>
              <!-- <loading-outlined v-if="licenseLoad"></loading-outlined>
              <plus-outlined v-else></plus-outlined>
              <div class="ant-upload-text">上传</div> -->
              <div><a>上传</a></div>
            </div>
          </a-upload>
        </div>
        <div @click="flag = 8" style="display:flex;flex-direction: column;">
          <div>法人身份证-正面</div>
          <a-image v-if="fileImg.positive" style="width:150px;height:150px" :src="fileImg.positive" />
          <a-upload v-model:file-list="fileList" list-type="picture" class="avatar-uploader" :show-upload-list="false"
            :before-upload="beforeUpload" @change="imgUpload">
            <div>
              <!-- <loading-outlined v-if="positiveLoad"></loading-outlined>
              <plus-outlined v-else></plus-outlined>
              <div class="ant-upload-text">上传</div> -->
              <div><a>上传</a></div>
            </div>
          </a-upload>
        </div>
        <div @click="flag = 9" style="display:flex;flex-direction: column;">
          <div>法人身份证-反面</div>
          <a-image v-if="fileImg.wrongSide" style="width:150px;height:150px" :src="fileImg.wrongSide" />
          <a-upload v-model:file-list="fileList" list-type="picture" class="avatar-uploader" :show-upload-list="false"
            :before-upload="beforeUpload" @change="imgUpload">
            <div>
              <!-- <loading-outlined v-if="wrongSideLoad"></loading-outlined>
              <plus-outlined v-else></plus-outlined>
              <div class="ant-upload-text">上传</div> -->
              <div><a>上传</a></div>
            </div>
          </a-upload>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from 'vue'
import { message } from 'ant-design-vue'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue'
import { setCompanyInfo, idCardCollect } from '@/api/carrier/cloudFlash'
import { fileUploadPublic as fileUpload } from '@/api/global'
import { mobileValidate, idNumberValidate, enterpriseValidate } from '@/utils/validate'
import { emit } from 'process'

export default {
  components: {
    LoadingOutlined,
    PlusOutlined
  },
  props: {
    carrierId: String,
    propsBizUserId: String,
    phoneType: Number,
    detail: Object
  },
  setup (props, { emit }) {
    const _formRef = ref(null)
    const state = reactive({
      carrierId: props.carrierId,
      uploadList: [],
      okText: props.detail.accountNoMask ? '编辑' : '确认',
      footer: props.detail.accountNoMask ? false : true,
      editShow: props.detail.accountNoMask ? false : true,
      licenseLoad: false,
      positiveLoad: false,
      wrongSideLoad: false,
      fileImg: {
        license: props.detail.license ? props.detail.license.url : '',
        positive: props.detail.frontIdCard ? props.detail.frontIdCard.url : '',
        wrongSide: props.detail.backIdCard ? props.detail.backIdCard.url : ''
      },
      setForm: props.detail || {
        accountNo: '',
        bizUserId: props.propsBizUserId,
        bankCityNo: '',
        bankName: '',
        parentBankName: '',
        province: '',
        city: '',
        companyAddress: '',
        companyName: '',
        expLicense: '',
        identityType: '',
        identityTypeName: '',
        legalIds: '',
        legalName: '',
        legalPhone: '',
        telephone: '',
        uniCredit: '',
        unionBank: ''
      },
      flag: null,
      disabled: props.detail ? true : false,
      loading: false,
      modelShow: false,
      documentType: [
        {
          label: '身份证',
          value: 1
        }, {
          label: '护照',
          value: 2
        }, {
          label: '军官证',
          value: 3
        }, {
          label: '回乡证',
          value: 4
        }, {
          label: '台胞证',
          value: 5
        }, {
          label: '警官证',
          value: 6
        }, {
          label: '士兵证',
          value: 7
        }, {
          label: '户口簿',
          value: 8
        }, {
          label: '港澳居民来往内地通行证',
          value: 9
        }, {
          label: '临时身份证',
          value: 10
        }, {
          label: '外国人居留证',
          value: 11
        }, {
          label: '港澳台居民居住证',
          value: 12
        }, {
          label: '其他',
          value: 99
        }
      ],
      rules: {
        accountNo: [{
          required: true,
          message: '请填写企业对公账户',
          trigger: 'blur',
        }, {
          pattern: /^[0-9\-]+$/,
          message: '请填写正确的对公账户',
          trigger: 'blur'
        }],
        legalPhone: [{
          required: true,
          message: '请填写法人手机号',
          trigger: 'blur',
        }, {
          pattern: mobileValidate,
          message: '请填写正确的手机号',
          trigger: 'blur'
        }],
        legalIds: [{
          required: true,
          message: '请填写法人证件号',
          trigger: 'blur',
        }, {
          pattern: idNumberValidate,
          message: '请填写正确的证件号码',
          trigger: 'blur'
        }],
        unionBank: [{
          required: true,
          message: '请填写支付行号',
          trigger: 'blur',
        }, {
          pattern: /^[0-9]{12}$/,
          message: '请填写正确的支付行号',
          trigger: 'blur',
        }],
        uniCredit: [{
          required: true,
          message: '请填写统一社会信用',
          trigger: 'blur',
        }, {
          pattern: enterpriseValidate,
          message: '请填写正确的信用代码',
          trigger: 'blur',
        }],
        bankName: { required: true, message: '请填写开户行支行名称', trigger: 'blur' },
        parentBankName: { required: true, message: '请填写开户银行名称', trigger: 'blur' },
        companyName: { required: true, message: '请填写企业名称', trigger: 'blur' },
        identityType: { required: true, message: '请选择证件类型' },
        unionBank: { required: true, message: '请填写支付行号' },
        legalName: { required: true, message: '请填写法人姓名', trigger: 'blur' }
      }
    })
    onMounted(() => {
      if (props.detail) {
        switch (props.detail.identityType) {
          case 1:
            state.setForm.identityTypeName = '身份证'
            break
          case 2:
            state.setForm.identityTypeName = '护照'
            break
          case 3:
            state.setForm.identityTypeName = '军官证'
            break
          case 4:
            state.setForm.identityTypeName = '回乡证'
            break
          case 5:
            state.setForm.identityTypeName = '台胞证'
            break
          case 6:
            state.setForm.identityTypeName = '警官证'
            break
          case 7:
            state.setForm.identityTypeName = '士兵证'
            break
          case 8:
            state.setForm.identityTypeName = '户口簿'
            break
          case 9:
            state.setForm.identityTypeName = '港澳居民来往内地通行证'
            break
          case 10:
            state.setForm.identityTypeName = '临时身份证'
            break
          case 11:
            state.setForm.identityTypeName = '外国人居留证'
            break
          case 12:
            state.setForm.identityTypeName = '港澳台居民居住证'
            break
          case 99:
            state.setForm.identityTypeName = '其他'
            break
        }
      }
      // console.log(props, '123');
      // bizUserId: "1233"
      // carrierId: "1589823546000584706"
      // phoneType: 9
    })
    const accountNoChange = (e, v) => {
      console.log(e, v, state.setForm.identityType);
      // state.setForm.identityType = e
    }
    const dateChange = (e, v) => {
      state.setForm.expLicense = v
    }
    // 图片上传
    const imgUpload = info => {
      if (state.flag === 1) state.licenseLoad = true
      if (state.flag === 8) state.positiveLoad = true
      if (state.flag === 9) state.wrongSideLoad = true
      state.fileList = [] // 单纯的定义不让此方法执行三次 BUG！！！
      const formData = new FormData()
      formData.append('file', state.uploadList[0])
      state.uploadList = []
      fileUpload(formData).then(response => {
        if (response.code === 10000) {
          idCardCollect({
            carrierId: state.carrierId,
            bizUserId: props.propsBizUserId,
            fileId: response.data.id,
            picType: state.flag
          }).then(res => {
            if (res.code === 10000) {
              message.success('上传成功')
              // emit('loadData')
              if (response.data.url) {
                if (state.flag === 1) state.licenseLoad = false, state.fileImg.license = response.data.url
                if (state.flag === 8) state.positiveLoad = false, state.fileImg.positive = response.data.url
                if (state.flag === 9) state.wrongSideLoad = false, state.fileImg.wrongSide = response.data.url
              }
              state.flag = null
            }
          })
        }
      })
    }
    const beforeUpload = file => {
      state.uploadList.push(file)
    }
    // 确认/编辑
    const editData = () => {
      if (state.editShow) {
        _formRef.value.validate().then(() => {
          if (state.setForm.accountNoMask !== '' && state.setForm.accountNoMask !== null && state.setForm.accountNoMask !== undefined) {
            state.okText = '编辑'
            state.editShow = false
            state.modelShow = false
            message.info('新增企业信息编辑--功能入口(待添加)') // 编辑功能入口
          } else {
            setCompanyInfo({ ...state.setForm, carrierId: state.carrierId, bizUserId: props.propsBizUserId }).then(res => {
              if (res.code === 10000) {
                message.success('新增企业信息成功')
                state.modelShow = false
                emit('loadData')
              }
            }).catch(err => { console.log(err); })
          }
        })
      }
      if (state.setForm.accountNoMask === '' || state.setForm.accountNoMask === null || state.setForm.accountNoMask === undefined) {
        // state.disabled = false
        // state.okText = '新增提交'
      } else {
        state.editShow = true
        state.okText = '编辑提交'
      }
    }
    return {
      ...toRefs(state),
      _formRef,
      imgUpload,
      accountNoChange,
      beforeUpload,
      dateChange,
      editData,
    }
  }
}
</script>

<style lang="less" scoped>@import './index.less';
@import '../common.less';

.form-set {
  max-height: 450px;
  overflow-y: scroll;
  margin-bottom: 15px;
  padding-right: 15px;
}</style>
