<template>
  <div>
    <a-modal v-model:visible="modelShow" width="600px" title="退款" @ok="refundBtn" :confirmLoading="btnLoading">
      <div>
        <a-form-item label="退款金额">
          <span>{{ form.amount }}</span>
          <!-- <a-input type="number" v-model:value="form.amount" /> -->
        </a-form-item>
<!--        <a-form-item label="付款方id">-->
<!--          <a-input v-model:value="form.bizUserId" disabled />-->
<!--        </a-form-item>-->
<!--        <a-form-item label="交易订单号">-->
<!--          <a-input v-model:value="form.oriBizOrderNo" disabled />-->
<!--        </a-form-item>-->
      </div>
    </a-modal>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { refund } from '@/api/carrier/cloudFlash'
import { message } from 'ant-design-vue'

export default {
  props: {
    carrierId: String,
    amount: String
  },
  setup (props) {
    const state = reactive({
      modelShow: false,
      btnLoading: false,
      form: {
        amount: props.amount,
        oriBizOrderNo: ''
      }
    })
    // 退款
    const refundBtn = () => {
      state.btnLoading = true
      refund(state.form).then(res => {
        if (res.code === 10000) {
          message.success('退款成功')
          state.modelShow = false
        }
      }).finally(()=>{state.btnLoading=false})
    }

    return {
      ...toRefs(state),
      refundBtn
    }
  }
}
</script>

<style lang="less" scoped>
</style>
